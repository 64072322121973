import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from './portfolio.style';

import BannerSection from './Banner';
import Navbar from './Navbar';
import AwardsSection from './Awards';
import About from './About';
import Experience from './Experience';
import Education from './Education';
import ProgrammingHistory from './ProgrammingHistory'
import Book from './Book';
import QiitaSection from './Qiita';
import PickupSection from './Pickup';
import CallToAction from './CallToAction';
import ClientsSection from './Clients';
import ContactSection from './Contact';
import Footer from './Footer';
import SEO from '../components/seo';
import Speakerdeck from './Speakerdeck'

import { withTrans } from '../i18n/withTrans'

import { library } from '@fortawesome/fontawesome-svg-core'; //fontawesomeのコアファイル
import { fab } from '@fortawesome/free-brands-svg-icons'; //fontawesomeのbrandアイコンのインポート
import { fas } from '@fortawesome/free-solid-svg-icons'; //fontawesomeのsolidアイコンのインポート
import { far } from '@fortawesome/free-regular-svg-icons'; //fontawesomeのregularアイコンのインポート
library.add(fab, fas, far); //他のコンポーネントから簡単に呼び出せるようにするための登録処理？

// const query = graphql`
//   query($lng: String!) {
//     locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
//       ...TranslationFragment
//     }
//   }
// `;

const Page = ({ t, i18n }) => {
  return (
    <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <SEO title="Yohei Sugigami" />
        <ResetCSS />
        <GlobalStyle />        
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />      
          {/* <About /> */}
          <Experience />
          <Education />
          <ProgrammingHistory />
          <Book />          
          <PickupSection />
          <Speakerdeck />                  
          <AwardsSection />
          {/* <ContactSection /> */}
          <QiitaSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default withTrans(Page);

